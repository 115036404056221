<template>
<div id="wrapper">
        <div id="header">
            <div id="logo">
                <img :src="`${publicPath}logo.png`" class="ml-3 p-2" />
            </div>
            <!-- <current-user /> -->
        </div>
        <sidebar />
        <div class="login-wrapper">
            <!-- TODO: make this a slot, put other stuff in common component? -->
            <show-general-errors />
            <div class="login-box">
                <div style="font-weight:300;font-size:36px;">Worker Login</div>
                <!-- TODO: make login form/logic a standalone component -->
                <!-- {{showingError}} -->
                <form class="pl-5 pt-1">
                <div class="d-flex flex-column mb-2">
                    <label for="username" class="mb-1">Email or username:</label>
                    <b-input autofocus v-model="username" :state="showingError ? false : buttonText == 'Success' ? true : null" />
                </div>
                <div class="d-flex flex-column mb-4">
                    <label for="password" class="mb-1">Password:</label>
                    <b-input v-on:keyup.enter="login" v-model="password" :state="showingError ? false : buttonText == 'Success' ? true : null" type="password" />
                </div>
                <div class="text-right">
                    <b-button variant="outline-primary" class="avo-basic-btn" @click="login">{{buttonText}}</b-button>
                </div>
                <div class="d-flex justify-content-end mt-1 mr-1">
                    <div class="avo-text-light-small mr-1">To log in as a provider, </div>
                    <a class="small-link" href="/provider-login/"> Click Here</a>
                </div>
                </form>
            </div>
        </div>
        <div id="footer"></div>
</div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue"
import { login } from '../common.js'
import ShowGeneralErrors from "../components/ShowGeneralErrors.vue"
import errorStore from "../errorStore.js";

export default {
  name: 'Index',
  components: {
      Sidebar,
      ShowGeneralErrors
  },
  props: [ 'target' ],
  data(){
      return {
          publicPath: process.env.BASE_URL,
          username: "",
          password: "",
          buttonText: "Log In",
      }
  },
  methods: {
      login: function(){
          var vm = this 
          this.buttonText = "Submitting..."
          login(this.username, this.password, vm, this.target).then(function(){
              vm.buttonText = "Success"
          }).catch((error) => {
              console.log(error);
              vm.buttonText = "Log in";
          });
      },
  },
  watch: {
      username(){
          errorStore.commit('hideAlerts');
      },
      password(){
          errorStore.commit('hideAlerts');
      }
  },
  computed: {
      showingError(){
          return errorStore.state.showAlerts;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-wrapper {
    height:100vh;
    background-color: white;
    padding: 20px;
    border-top-left-radius: 15px;
}
.login-box {
    display: flex;
    flex-direction: column;
    max-width: 300px;
}
input {
    border: 1px solid #C9D2DF;
    border-radius: 5px !important;
    max-width:650px;
    height: 30px;
}
label {
    font-size: 14px;
}
.avo-basic-btn {
    width: 100% !important;
}
.small-link {
    font-size: 12px;
}
</style>