// VueRouter definition including routing guards for role-based views
import Vue from 'vue'
import VueRouter from 'vue-router'

// utility functions
import {
    userIsAuthenticated,
    userHasJWTSet,
    removeLoginCredentials,
    hasSuperAdminRole,
    hasRole,
    getUserStartingPageName,
} from './common.js'
import rolesEnum from './rolesEnum.js'

// components
import Login from "./login/Login.vue"
import ProviderLogin from "./login/ProviderLogin.vue"
// Using VueRouter Lazy Loading Routes technique: 
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// to split up Webpack bundle by component
const ClaimsPortal = () => import("./claimsPortal/ClaimsPortal.vue")
const Claims = () => import("./claims/Claims.vue")
const Practices = () => import("./practices/Practices.vue")
const Payers = () => import("./payers/Payers.vue")
const Reports = () => import("./reports/Reports.vue")
const Workers = () => import("./workers/Workers.vue")
const WorkerView = () => import("./components/workers/WorkerView.vue")
const Reset = () => import("./components/userRegistration/SetNewPassword.vue")
const Invite = () => import("./userInvite/Invite.vue")
const Notes = () => import("./notes/Notes.vue")
const Demo = () => import("./demo/Demo.vue")
const Admin = () => import("./admin/Admin.vue")
const ProviderPortal = () => import("./providerPortal/ProviderPortal.vue")
const ProviderDash = () => import("./components/providerPortal/ProviderDash.vue")
const ProviderPreauths = () => import("./components/providerPortal/ProviderPreauths.vue")
const PatientEstimate = () => import("./components/providerPortal/PatientEstimate.vue")
const ProviderReports = () => import("./components/providerPortal/ProviderReports.vue")
const Users = () => import("./users/Users.vue")
const Data = () => import("./dataManagement/Data.vue")
const CronJobs = () => import("./cronJobs/CronJobs.vue")
const CodeLookup = () => import("./codeLookup/CodeLookup.vue")
const TaxonomyCSVUpload = () => import("./components/documents/TaxonomyCSVUpload.vue")
const FeeScheduleCSVUpload = () => import("./components/documents/FeeScheduleCSVUpload.vue")

// TODO: Add more meta information to each route about specific tabs, including viewer information for tabs
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '',
            component: ClaimsPortal,
            children: [
                {
                    path: '',
                    redirect: '/claims/'
                },
                {
                    path: '/claims/',
                    name: 'claims',
                    component: Claims,
                    props: { issues: false },
                    meta: {
                        title: "Avosina - Claims",
                        header: "Claims",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.BILLING_ADMIN,
                            rolesEnum.WORKER,
                        ],
                    }
                },
                {
                    path: '/claims/claim/:initialClaimId',
                    name: 'claim-view',
                    component: Claims,
                    props: route => ({
                        initialClaimId: route.params.initialClaimId,
                        issues: null,
                    }),
                    meta: {
                        title: function (params) { return "Avosina - Claim " + params.initialClaimId },
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.BILLING_ADMIN,
                            rolesEnum.WORKER,
                        ],
                    }
                },
                {
                    path: '/issues-claims/',
                    name: 'issues-claims',
                    component: Claims,
                    props: { issues: true },
                    meta: {
                        title: "Avosina - Issue Claims",
                        header: "Issues Claims",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.BILLING_ADMIN,
                            rolesEnum.ISSUES_WORKER,
                        ],
                    }
                },
                {
                    path: '/issues-claims/claim/:initialClaimId',
                    name: 'issue-claim-view',
                    component: Claims,
                    props: route => ({
                        initialClaimId: route.params.initialClaimId,
                        issues: true,
                    }),
                    meta: {
                        title: function (params) { return "Avosina - Issue Claim " + params.initialClaimId },
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.BILLING_ADMIN,
                            rolesEnum.ISSUES_WORKER,
                        ],
                    }
                },
                {
                    path: '/practices/',
                    name: 'practices',
                    component: Practices,
                    meta: {
                        title: "Avosina - Practices",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.BILLING_ADMIN,
                            rolesEnum.WORKER,
                        ],
                        privileges: {
                            'edit': [
                                rolesEnum.SUPERADMIN, // editing: edit fee schedule, add providers, add locations, edit practice info, edit master password
                            ],
                            'summary_tab': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.BILLING_ADMIN,
                            ],
                            'see_all_practices': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.BILLING_ADMIN,
                            ],
                            'reports_tab': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.BILLING_ADMIN,
                            ],
                        }
                    }
                },
                {
                    path: '/payers/',
                    name: 'payers',
                    component: Payers,
                    meta: {
                        title: "Avosina - Payers",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.BILLING_ADMIN,
                            rolesEnum.WORKER,
                        ],
                        privileges: {
                            'resources_tab': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.BILLING_ADMIN,
                            ],
                            'summary_tab': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.BILLING_ADMIN,
                            ],
                            'select_payer': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.BILLING_ADMIN,
                            ],
                            'assign_resources': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.BILLING_ADMIN,
                            ],
                            'edit': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.BILLING_ADMIN,
                            ],
                        }
                    }
                },
                {
                    path: '/reports/',
                    name: 'reports',
                    component: Reports,
                    meta: {
                        title: "Avosina - Reports",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.BILLING_ADMIN,
                        ]
                    }
                },
                {
                    path: '/workers/',
                    name: 'workers',
                    component: Workers,
                    meta: {
                        title: "Avosina - Worker Performance",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.BILLING_ADMIN,
                        ],
                    }
                },
                {
                    path: '/me/',
                    name: 'my-work',
                    component: WorkerView,
                    meta: {
                        title: "Avosina - My Work",
                        dash: "claims",
                    }
                },
                {
                    path: '/reset/*',
                    name: 'reset',
                    component: Reset,
                    meta: {
                        title: "Avosina - Reset Password",
                        dash: "claims",
                    }
                },
                {
                    path: '/invite/',
                    name: 'invite',
                    component: Invite,
                    meta: {
                        title: "Avosina - Invite Users",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/notes/',
                    name: 'notes',
                    component: Notes,
                    meta: {
                        title: "Avosina - Notes",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/demo/',
                    name: 'demo',
                    component: Demo,
                    meta: {
                        title: "Avosina - Demo",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/admin/',
                    name: 'admin',
                    component: Admin,
                    meta: {
                        title: "Avosina - Admin Management",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/admin/users/',
                    name: 'users',
                    component: Users,
                    meta: {
                        title: "Avosina - User Management",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/admin/data/payers/',
                    name: 'payers-data',
                    component: Data,
                    meta: {
                        title: "Avosina - Admin Payers Data Management",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/admin/data/cronjobs/',
                    name: 'cronjobs-data',
                    component: CronJobs,
                    meta: {
                        title: "Avosina - Admin CronJob Management",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/admin/data/fee-schedules/',
                    name: 'fee-schedule-data',
                    component: FeeScheduleCSVUpload,
                    meta: {
                        title: "Avosina - Admin Fee Schedule Upload",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/admin/data/taxonomy-codes/',
                    name: 'taxonomy-code-data',
                    component: TaxonomyCSVUpload,
                    meta: {
                        title: "Avosina - Admin Taxonomy Code Upload",
                        dash: "claims",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                        ],
                    }
                },
                {
                    path: '/codelookup/',
                    name: 'codelookup',
                    component: CodeLookup,
                    meta: {
                        title: "Avosina - Code Lookup",
                        dash: "claims",
                    }
                },
            ]
        },
        {
            path: '/provider-portal/',
            component: ProviderPortal,
            children: [
                {
                    path: '',
                    name: 'provider-dashboard',
                    component: ProviderDash,
                    meta: {
                        title: "Avosina - Provider Dashboard",
                        header_text: "Provider Dashboard",
                        dash: "providerportal",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.PRACTICE_ADMIN,
                            rolesEnum.ADMINISTRATIVE_PHYSICIAN,
                            rolesEnum.PHYSICIAN,
                            rolesEnum.PREAUTH_WORKER,
                        ],
                        privileges: {
                            'see_metrics': [
                                rolesEnum.PREAUTH_WORKER,
                                rolesEnum.SUPERADMIN,
                                rolesEnum.PRACTICE_ADMIN,
                                rolesEnum.ADMINISTRATIVE_PHYSICIAN,
                            ],
                            'see_other_provider_metrics': [
                                rolesEnum.SUPERADMIN,
                                rolesEnum.PRACTICE_ADMIN,
                                rolesEnum.ADMINISTRATIVE_PHYSICIAN,
                            ],
                        }
                    }
                },
                {
                    path: 'preauths/',
                    name: 'provider-preauths',
                    component: ProviderPreauths,
                    meta: {
                        title: "Avosina - Provider Preauthorizations",
                        header_text: "Preauthorizations",
                        dash: "providerportal",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.PRACTICE_ADMIN,
                            rolesEnum.ADMINISTRATIVE_PHYSICIAN,
                            rolesEnum.PHYSICIAN,
                            rolesEnum.PREAUTH_WORKER,
                        ],
                    }
                },
                {
                    path: 'estimates/',
                    name: 'estimates',
                    component: PatientEstimate,
                    meta: {
                        title: "Avosina - Patient Estimates",
                        header_text: "Patient Estimate",
                        dash: "providerportal",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.PRACTICE_ADMIN,
                            rolesEnum.ADMINISTRATIVE_PHYSICIAN,
                            rolesEnum.PHYSICIAN,
                            rolesEnum.PREAUTH_WORKER,
                        ],
                    }
                },
                {
                    path: 'reports/',
                    name: 'provider-reports',
                    component: ProviderReports,
                    meta: {
                        title: "Avosina - Provider Reports",
                        header_text: "Reports",
                        dash: "providerportal",
                        viewers: [
                            rolesEnum.SUPERADMIN,
                            rolesEnum.PRACTICE_ADMIN,
                            rolesEnum.ADMINISTRATIVE_PHYSICIAN,
                            rolesEnum.PHYSICIAN,
                            rolesEnum.PREAUTH_WORKER,
                        ],
                    }
                },
            ]
        },
        {
            path: '/provider-login/:target',
            name: 'provider-login',
            props: true,
            component: ProviderLogin,
            meta: {
                title: "Avosina - Provider Login",
                dash: "providerportal",
            }
        },
        {
            path: '/login/:target',
            name: 'login',
            props: true,
            component: Login,
            meta: {
                title: "Avosina - Login",
                dash: "claims",
            }
        },
        {
            path: '/users/',
            redirect: '/admin/users/',
        },
        {
            path: '/data/',
            redirect: '/admin/data/payers/',
        },
        {
            path: '*',
            redirect: '/claims/',
        },
    ],
})

function isViewerOfRoute(to) {
    if (hasSuperAdminRole()) return true
    else return (!to?.meta?.viewers) || (to?.meta?.viewers?.some((role) => hasRole(role)))
}

router.beforeEach(async (to, from, next) => {
    var auth = userHasJWTSet();

    // set page title
    Vue.nextTick(() => {
        if (to?.meta?.title instanceof Function) {
            document.title = to.meta.title(to.params)
        } else {
            document.title = to?.meta?.title || "Avosina";
        }
        if (process.env.VUE_APP_API_MODE && process.env.VUE_APP_API_MODE == "staging") {
            document.title = document.title + " - STAGING DEPLOYMENT"
        }
    });

    // if already authorized, redirect login page to the appropriate dash,
    // and reroute viewers trying to access pages they're not allowed on
    if (auth) {
        if (to.name == 'login' || to.name == 'reset' || to.name == 'provider-login') {
            next({ name: to?.meta?.dash || 'claims' })
        } else if (!isViewerOfRoute(to)) {
            next({ name: getUserStartingPageName() })
        } else {
            next()
        }
    }
    // if not yet authorized, redirect anyone who is not going to the login pages to the login page
    else {
        if (to.name !== 'login' && to.name != 'provider-login' && to.name !== 'reset') {
            // [AV-538] TODO: Pass "to" as a parameter so the login page can redirect where the user was going
            next({ name: 'login', params: { target: to.name } })
        } else {
            next()
        }
    }
});

router.afterEach(async (to) => {
    var auth = await userIsAuthenticated();

    if (!auth) {
        removeLoginCredentials();
        if (to.name !== 'login' && to.name != 'provider-login' && to.name !== 'reset') {
            window.location.replace("/login/")
        }
    }
})

export default router