import Vue from 'vue';
import { authenticated_request, make_url } from './../common.js'

const claimsStoreProto = {
    state: {
        searchParams: {
             search_terms: "",
             age_ranges: "",
             states: "",
             payer_ids: "",
             worker_ids: "",
             location_ids: "",
             provider_ids: "",
             is_closed: null,
        },
        searchReady: false,
        searchObject: null,
        selectedClaim: null,
        newClaimStateValue: null,
        newClaimStateLabel: null,
        newClaimStateShort: null,
        newClaimWorker: null,
        refresh: null,
       
        insurances: null,

        workedClaim: null,
        workedClaimActiveTab: 'WorkClaimSummary',
        workedClaimLoadedTabs: ['WorkClaimSummary'],
        claimTransitionedTo: null,

        updateCPTCodes: false,
        updateBilling: false,
        updatePayments: false,

        activityLogsClaimId: null,
        activityLogs: {},
        reloadActivityLogs: false,
        reloadActivitySummary: false,

        reloadTasks: false,
        reloadTasksSummary: false,
        
        notesListClaimId: null,
        notesListPreauthId: null,
        notesList: {},
        reloadNotesSummary: false,
        reloadWorkLogSummary: false,
        reloadDocuments: false,

        notesTypes: null,
        documentTypes: null,
        worklogOptionsMap: null,
        workers: [],
       
    },
    mutations: {
      initializeStore(state) {
         // Retrieve searchObject from localStorage
         console.log("Initializing store");
         if (localStorage.getItem('claims_search_filters')) {
            const savedSearchFilters = localStorage.getItem('claims_search_filters');
            console.log("Restoring claims_search_filters from localStorage", savedSearchFilters);
            state.searchObject = JSON.parse(savedSearchFilters);
         }
         if (localStorage.getItem('claims_search_params')) {
            const savedSearchParams = localStorage.getItem('claims_search_params');
            console.log("Restoring claims_search_params from localStorage", savedSearchParams);
            state.searchParams = JSON.parse(savedSearchParams);
         }
      },
      setSearchReady(state, bool) {
         state.searchReady = bool;
      },

      //  for searching/refreshing the ClaimList
      setSearchParams: function(state, newparams){
         state.searchParams = {...newparams};
         localStorage.setItem('claims_search_params', JSON.stringify(state.searchParams));
      },
      setSearchObject: function(state, newSearchObject){
         state.searchObject = newSearchObject;
         localStorage.setItem('claims_search_filters', JSON.stringify(newSearchObject));
      },
      setRefresh: function(state, bool){
         state.refresh = bool;
      },

      // For updating claim state (from ClaimPreview.vue --> update ClaimList)
      setNewClaimStateValue: function(state, state_value) {
         state.newClaimStateValue = state_value;
      },
      setNewClaimStateLabel: function(state, state_label) {
         state.newClaimStateLabel = state_label;
      },
      setNewClaimStateShort: function(state, state_short) {
         state.newClaimStateShort = state_short;
      },
      clearNewClaimState: function(state) {
         state.newClaimStateValue = null;
         state.newClaimStateLabel = null;
         state.newClaimStateShort = null;
      },

      // for transitioning claim state (from WorkLogAdd.vue --> update WorkLog.vue)
      transitionClaimState: function(state, transition) {
         state.claimTransitionedTo = transition;
      },
      clearClaimTransition: function(state){
         state.claimTransitionedTo = null;
      },      

      // for updating claim-worker assignment
      setNewClaimWorker: function(state, claim_worker) {
         state.newClaimWorker = claim_worker;
         state.reloadActivityLogs = true;
         state.reloadActivitySummary = true;
      },

      // for selecting/deselecting claims (for ClaimPreview and WorkClaim)
      setSelectedClaim: function(state, claim) {
         state.selectedClaim = claim;
         state.workedClaim = null;
      },
      clearSelectedClaim: function(state) {
         state.selectedClaim = null;
      },
      setWorkedClaim: function(state, claim) {
         state.workedClaim = claim;
         state.selectedClaim = null;
      },
      clearWorkedClaim: function(state) {
         state.workedClaim = null;
         state.workedClaimLoadedTabs = [];
         state.reloadActivityLogs = false;
         state.reloadActivitySummary = false;
         state.reloadTasks = false;
         state.reloadTasksSummary = false;
         state.reloadNotesSummary = false;
         state.reloadWorkLogSummary = false;
         state.reloadDocuments = false;
      },
      
      setWorkedClaimCodes: function(state, cpt_codes) {
         Vue.set(state.workedClaim, 'cpt_codes', cpt_codes);
      },
       
      setInsurances: function(state, insurances) {
         state.insurances = insurances;
      },

      //   WorkClaimView tab navigation
      setWorkedClaimActiveTab: function(state, component){
         state.workedClaimActiveTab = component;
         if (!state.workedClaimLoadedTabs.includes(component)) {
            state.workedClaimLoadedTabs.push(component);
         }
      },

      setUpdateCPTCodes: function(state, bool){
         state.updateCPTCodes = bool;
      },
      setUpdateBilling: function(state, bool){
         state.updateBilling = bool
      },
      setUpdatePayments: function(state, bool){
         state.updatePayments = bool;
      },
      
      // for refreshing the activity logs (on both Summary and Tasks tabs) when new notes/docs/etc. are created in WorkClaimView
      refreshActivityLog: function(state){
         state.reloadActivityLogs = true;
         state.reloadActivitySummary = true;
      },
      activityLogRefreshed: function(state){
         state.reloadActivityLogs = false;
      },
      activitySummaryRefreshed: function(state){
         state.reloadActivitySummary = false;
      },

      // for refreshing the task list (on both Summary and Tasks tabs) when new tasks/new tasks from worklog are created
      refreshTasks: function(state){
         state.reloadTasks = true;
         state.reloadTasksSummary = true;
      },
      tasksRefreshed: function(state){
         state.reloadTasks = false;
      },
      tasksSummaryRefreshed: function(state){
         state.reloadTasksSummary = false;
      },

      // other refresh for WorkClaimSummary notes & worklogs
      refreshWorkLogSummary: function(state){
         state.reloadWorkLogSummary = true;
      },
      workLogSummaryRefreshed: function(state){
         state.reloadWorkLogSummary = false;
      },
      refreshDocuments: function(state){
         state.reloadDocuments = true;
      },
      documentsRefreshed: function(state){
         state.reloadDocuments = false;
      },
        
      // shared options so we don't load them more than once (cleared on clearWorkedClaim)
      // NOTE: these should only be used for autocomplete/dropdown options type requests
      saveWorkers: function(state, workers){
         state.workers = workers;
      },
      setWorklogOptionsMap: function(state, map){
         state.worklogOptionsMap = map;
      },
      setNotesTypes: function(state, types){
         state.notesTypes = types;
      },
      setDocumentTypes: function(state, types){
         state.documentTypes = types;
      },

      // Activity logs
      setActivityLogs: function(state, logs){
         state.activityLogs = logs;
      },
      invalidateActivityLogs: function(state){
         state.activityLogs = null;
         state.activityLogsClaimId = null;
      },
      setActivityLogsClaimId: function(state, claimId){
         state.activityLogsClaimId = claimId;
      },
      
      // Notes list
      setNotesList: function(state, notesResponseData){
         state.notesList = notesResponseData;
      },
      invalidateNotesList: function(state){
         state.notesList = null;
         state.notesListPreauthId = null;
         state.notesListClaimId = null;
      },
      setNotesListClaimId: function(state, claimId){
         state.notesListClaimId = claimId;
         // clear preauth id
         state.notesListPreauthId = null;
      },
      setNotesListPreauthId: function(state, preauthId){
         state.notesListPreauthId = preauthId;
         // clear claim id
         state.notesListClaimId = null;
      }
       
   },
   actions: {
      fetchClaimActivityLogs: function(context, payload){
        const { claimId, params } = payload;
        context.commit('setActivityLogsClaimId', null);
        const url = make_url("/claims/" + claimId + "/activity");
        authenticated_request({
            method: "get",
            url: url,
            params: params
        }).then(function(response){
            context.commit('setActivityLogs', response.data);
            context.commit('setActivityLogsClaimId', claimId);
        }).catch((e)=>{
            console.log(e);
            context.commit('setActivityLogsClaimId', null);
        });
      },
      
      fetchNotesList: function(context, params) {
         context.commit('setNotesListClaimId', null);
         context.commit('setNotesListPreauthId', null);
			const url = make_url("/reports/notes");
			authenticated_request({
				method: "get",
				url: url,
				params: params
			}).then(function(response){
            context.commit('setNotesList', response.data);
            if (params.claim_id) {
               context.commit('setNotesListClaimId', params.claim_id);
            }
            else if (params.preauth_id) {
               context.commit('setNotesListPreauthId', params.preauth_id);
            }
			}).catch((e) => {
            console.log(e);
            context.commit('setNotesListClaimId', null);
            context.commit('setNotesListPreauthId', null);
         });
      },

      fetchWorklogOptionsMap: function(context){
         context.commit('setWorklogOptionsMap', {});
         authenticated_request({
            method: "get",
            url: make_url("/worklogs/options")
         }).then(function(response){
            const items = response.data;
            context.commit('setWorklogOptionsMap', items);
         }).catch(function(error){
            console.log(error.response)
            context.commit('setWorklogOptionsMap', null);
         });
      },

      fetchDocumentTypesMap: function(context){
         context.commit('setDocumentTypes', []);
         authenticated_request({
            method: "get",
            url: make_url("/lookup/documents/types")
         }).then(function(response){
            const items = response.data;
            context.commit('setDocumentTypes', items);
         }).catch(function(error){
            console.log(error.response)
            context.commit('setDocumentTypes', null);
         });
      },
      
      fetchNotesTypesMap: function(context){
         context.commit('setNotesTypes', []);
         authenticated_request({
            method: "get",
            url: make_url("/lookup/notes/types")
         }).then(function(response){
            const items = response.data;
            context.commit('setNotesTypes', items);
         }).catch(function(error){
            console.log(error.response)
            context.commit('setNotesTypes', null);
         });
      },

   }
  };

export { claimsStoreProto };