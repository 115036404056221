<template>
<div id="portal-wrapper">
        <div id="portal-header">
            <div class="ml-5 page-title">
                <!-- <span>Provider Portal Login</span> -->
            </div>
        </div>
        
        <provider-portal-sidebar id="portal-sidebar" :providerLogin="true"/>
        
        <div id="content" class="pl-5">
            <!-- <div class="login-wrapper"> -->
                <!-- TODO: make this a slot, put other stuff in common component? -->
                <show-general-errors />
                <div class="login-box">
                    <div style="font-weight:300;font-size:36px;">Provider Login</div>
                    <!-- TODO: make login form/logic a standalone component -->
                    <form class="pt-4">
                        <div class="d-flex flex-column mb-2">
                            <label for="username" class="mb-1">Email or username:</label>
                            <b-input autofocus v-model="username" :state="showingError ? false : buttonText == 'Success' ? true : null" />
                        </div>
                        <div class="d-flex flex-column mb-4">
                            <label for="password" class="mb-1">Password:</label>
                            <b-input v-on:keyup.enter="login" v-model="password" type="password" :state="showingError ? false : buttonText == 'Success' ? true : null"/>
                        </div>
                        <div class="text-right">
                            <b-button variant="outline-primary" class="avo-basic-btn" @click="login">{{buttonText}}</b-button>
                        </div>
                    </form>
                </div>
            <!-- </div> -->
        </div>
        <!-- <div id="footer"></div> -->
</div>
</template>

<script>
import { login } from '../common.js'
import errorStore from "../errorStore.js";
import ShowGeneralErrors from "../components/ShowGeneralErrors.vue"
import ProviderPortalSidebar from "../components/providerPortal/ProviderPortalSidebar.vue"

export default {
  name: 'ProviderLogin',
  components: {
      ShowGeneralErrors,
      ProviderPortalSidebar,
  },
  data(){
      return {
          username: "",
          password: "",
          buttonText: "Log In",
      }
  },
  methods: {
      login: function(){
          const vm = this;
          this.buttonText = "Submitting...";
          login(this.username, this.password, vm).then(function(){
              vm.buttonText = "Success"
          }).catch((error) => {
              console.log(error);
              vm.buttonText = "Log In";
          });
      }
  },
  watch: {
      username(){
          errorStore.commit('hideAlerts');
      },
      password(){
          errorStore.commit('hideAlerts');
      }
  },
  computed: {
      showingError(){
          return errorStore.state.showAlerts;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#portal-wrapper {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: min-content auto;
    grid-template-areas:
    "sidebar header"
    "sidebar content";
    /* "footer footer"; */
    background-color: #F8FAFD !important;
}
#portal-header {
    align-items: center;
    position:sticky;
    top:0;
    height:70px;
    grid-area: header;
    display: grid;
    grid-template-columns: 25em auto;
    grid-template-areas:
    "content current-user";
    background-color: #F8FAFD !important;
    z-index:50;
}
#portal-sidebar {
    position:sticky;
    top:0;
    height:100vh;
    grid-area: sidebar;
    z-index:50;
}
#portal-logo {
    width: 4em;
    grid-area: logo;
}
#portal-logo img {
    width: 90%;
}
#current-user {
    grid-area: current-user;
    justify-self: end;
    align-self: center;
}
#app {
    grid-area: content;
}
#content {
    width:100%;
    background-color: #F8FAFD;
    min-height: 90vh;
}
.page-title {
    font-weight:500;
    font-size:22px;
    color: var(--dark);
}

.login-box {
    display: flex;
    flex-direction: column;
    max-width: 300px;
}
input {
    border: 1px solid #C9D2DF;
    border-radius: 5px !important;
    max-width:650px;
    height: 30px;
}
label {
    font-size: 14px;
}
.avo-basic-btn {
    width: 100% !important;
}

</style>