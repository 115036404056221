// common JS functions/etc.

import axios from "axios";
import errorStore from "./errorStore";

import rolesEnum from "./rolesEnum";

/* General error handler for axios */
function handleErrors(error) {
    /* handle 404/401/500 errors */
    let messages = [];

    // handles 401 errors
    if (error.response?.status == 401) {
        window.location.replace("/login/");
    }
    // handles general 404 errors
    else if (error.response?.status == 404) {
        if (!error.response?.data) {
            console.log("Resource not found");
            messages.push("The requested resource or page could not be located.");
        }
    }
    // handles 403 errors
    else if (error.response?.status == 403) {
        messages.push("You do not have the required permissions to access this resource.");
    }
    // handles 405 errors
    else if (error.response?.status == 405) {
        messages.push("Method not allowed");
    }
    // handles 500 errors
    else if (error.response?.status == 500) {
        console.log("Internal Server Error");
        messages.push("We are currently experiencing a service interruption. If the problem you are experiencing persists, please contact support.");
    }
    else {
        throw (error)
    }

    errorStore.commit("displayAlerts", messages);
    return
}

/* eslint no-debugger: "off" */
function authenticated_request(spec, onFailure = handleErrors) {
    /* Proxy for axios that injects the auth bearer token header
       
       TODO: in the future, handle expired tokens
    */
    const jwt = localStorage.getItem("jwt");

    if (!jwt) {
        return Promise.resolve({});
    }

    if (spec.headers) {
        spec.headers = Object.assign(spec.headers, { "Authorization": "Bearer " + jwt })
    } else {
        spec.headers = { "Authorization": "Bearer " + jwt }
    }

    //debugger;
    let promise = axios.apply(this, arguments);

    // catching general errors
    promise.catch(function (error) {
        onFailure(error);
    })

    return promise

}

function cancellable_request() {
    /* Proxy for axios that injects the auth bearer token header
       
       TODO: in the future, handle expired tokens
    */
    const jwt = localStorage.getItem("jwt");

    if (!jwt) {
        return Promise.resolve({});
    }

    let spec = arguments[0];
    if (spec.headers) {
        spec.headers = Object.assign(spec.headers, { "Authorization": "Bearer " + jwt })
    } else {
        spec.headers = { "Authorization": "Bearer " + jwt }
    }

    //add a cancel token
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    if (spec.cancelToken) {
        spec.cancelToken = Object.assign(spec.cancelToken, source.token);
    } else {
        spec.cancelToken = source.token;
    }
    //debugger;
    let promise = axios.apply(this, arguments);

    // catching general errors
    promise.catch(function (error) {
        handleErrors(error);
    })

    return [promise, source]

}

function unauthenticated_request() {
    /* Much more silly proxy for axios, to maintain consistency in the code-base
    */
    let promise = axios.apply(this, arguments);

    // catching general errors
    promise.catch(function (error) {
        handleErrors(error);
    })

    return promise

}


/* eslint no-debugger: "off" */
function make_url(path) {
    // construct an API path, returns as a string
    path = `/v${process.env.VUE_APP_API_VERSION}${path}`;
    return (new URL(path, process.env.VUE_APP_API_URL)).toString();
}

/* eslint no-debugger: "off" */
function checkauth() {
    const jwt = localStorage.getItem("jwt");

    // if it's not set, login
    if (!jwt) {
        window.location.replace("/login/");
    }

    // make sure the token is still valid
    authenticated_request({
        method: "post",
        url: make_url("/authentication/check"),
        validateStatus: null
    }).then(function (response) {
        if (response.status == 401) {
            window.location.replace("/login/");
        }
    });
}

function userHasJWTSet() {
    const jwt = localStorage.getItem("jwt");
    if (!jwt) {
        console.log("No jwt found in local storage");
        return false;
    } else {
        return true;
    }
}

async function userIsAuthenticated() {
    const jwt = localStorage.getItem("jwt");
    if (!jwt) {
        console.log("No JWT found in local storage");
        return false;
    }
    console.log("JWT found in local storage");
    console.log(jwt);

    // check that token is still valid
    let response = await authenticated_request({
        method: "post",
        url: make_url("/authentication/check"),
        validateStatus: null
    }).then(function (response) {
        if (response.status != 204) {
            console.log("Got bad response from auth check");
            return false;
        } else {
            console.log("Got good response from auth check");
            errorStore.commit('recordAuthenticated');
            return true;
        }
    });

    return response;
}

function removeLoginCredentials() {
    console.log("Purging login credentials token");
    localStorage.removeItem("jwt");
}

function login(username, password, vm, targetRoute) {
    const promise = axios.post(make_url("/authentication/login"),
        {
            username: username,
            password: password
        }).then(function (response) {
            // TODO: check return code, handle errors.
            if (response.data) {
                errorStore.commit("hideAlerts");
                console.log("JWT: " + response.data);
                localStorage.setItem("jwt", response.data);
                setUserIdAssociations().then(
                    () => setUserRole().then(
                        () => {
                            if (targetRoute !== null) vm.$router.push({ name: targetRoute })
                            // Check if the current route is still login, in case user has clicked away
                            else if (["login", "provider-login"].includes(vm.$route.name)) goToDashboard(vm);
                        }
                    )
                );
            }
        }).catch(function (error) {
            console.log(error.response)

            let messages = [];
            errorStore.commit("hideAlerts");
            if (error.response?.status) {
                if (error.response.status == 400) {
                    messages.push("Please enter a username and password");
                }
                if (error.response.status == 401) {
                    messages.push("Incorrect username or password");
                }
            }
            else {
                messages.push("Something went wrong logging you in")
            }
            errorStore.commit("displayAlerts", messages);
            throw (error);
        });
    return promise;
}

function setUserIdAssociations() {
    const promise = authenticated_request({
        method: "get",
        url: make_url("/me"),
    }).then(function (response) {
        const workers = response.data.workers;
        const providers = response.data.providers;
        const provider_practices = response.data.practice_providers;
        const practices = response.data.practices;
        const practice_ids = response.data.practice_ids;

        // TODO: better account for the circumstance where user-worker/provider/practice is one-to-many instead of one-to-one/many-to-one
        if (workers.length > 0) {
            localStorage.setItem("worker_id", workers[0].worker_id);
            console.log("Worker ID: " + workers[0].worker_id);
        } else {
            console.log("No workers associated with user")
        }
        if (providers.length > 0) {
            localStorage.setItem("provider", JSON.stringify(providers[0]));
            console.log("Provider: " + providers[0]);
        } else {
            console.log("No providers associated with user")
        }
        if (provider_practices.length > 0 && providers.length > 0) {
            localStorage.setItem("practice_id", provider_practices[0].practice_id);
            localStorage.setItem("practice", JSON.stringify(provider_practices[0]));
            console.log("Practice ID: " + provider_practices[0].practice_id);
        } else {
            console.log("No practices associated with provider")
        }
        if (practice_ids.length > 0) {
            localStorage.setItem("practice_id", practice_ids[0]);
            console.log("Practice ID: " + practices[0])
        }
        if (practices.length > 0) {
            localStorage.setItem("practice", JSON.stringify(practices[0]));
            console.log("Practice: " + practices[0])
        } else {
            console.log("No practices associated with user")
        }
        localStorage.setItem("user_id", response.data.user.user_id);
        console.log("User ID: " + response.data.user.user_id);

        const user_first_name = response.data.user.given_name;
        const user_family_name = response.data.user.family_name;
        let user_full_name;
        if (!user_first_name || !user_family_name) {
            user_full_name = response.data.user.username ? response.data.user.username : "Name not set";
        } else {
            user_full_name = response.data.user.given_name + ' ' + response.data.user.family_name;
        }
        localStorage.setItem("user_full_name", user_full_name);
        console.log("User display name: " + response.data.user.user_full_name);
    }).catch(function (error) {
        console.log(error.response)
    });
    return promise;
}

function setUserRole() {
    const promise = authenticated_request({
        method: "get",
        url: make_url("/me/roles"),
    }).then(function (response) {
        const roles = response.data;
        localStorage.setItem("user_roles", JSON.stringify(roles));
        console.log("user roles: ")
        console.log(roles);
    }).catch(function (error) {
        console.log(error.response)
    });
    return promise;
}

function listUserRoleNames() {
    let roleNamesArr = [];
    const storedRoles = localStorage.getItem("user_roles")

    if (storedRoles) {
        roleNamesArr = JSON.parse(storedRoles).map(function (obj) {
            return obj.role_name
        })
    }
    return roleNamesArr;
}

function hasSuperAdminRole() {
    return hasRole(rolesEnum.SUPERADMIN)
}

function hasRole(role_name) {
    const roles = listUserRoleNames();
    return roles.includes(role_name)
}

const alertsMixin = {
    data() {
        return {
            showAlert: false,
            alertMessage: "",
            avoAlerts: [],
        }
    },
    methods: {
        setShowAlert: function (show, error) {
            // legacy method for backwards compatibility
            if (!show) {
                this.hideAvoAlerts();
            } else {
                this.showAvoAlert(error);
                this.alertMessage = error;
                this.showAlert = true;
            }
        },
        showAvoAlertGeneral: function (message, variant) {
            this.avoAlerts.unshift({
                variant: variant,
                message: message,
                dismissible: true,
                show: true,
            });
        },
        showAvoSuccess: function (message) {
            this.showAvoAlertGeneral(message, "success");
        },
        showAvoInfo: function (message) {
            this.showAvoAlertGeneral(message, "info");
        },
        showAvoAlert: function (message) {
            this.showAvoAlertGeneral(message, "danger");
        },
        hideAvoAlerts: function () {
            this.avoAlerts = [];
            this.showAlert = false;
        },
    }
}

const generalPrivileges = {
    'see_claims_balance_summary': [
        rolesEnum.SUPERADMIN,
        rolesEnum.BILLING_ADMIN,
    ],
    'post_payments': [
        // rolesEnum.SUPERADMIN, // Removed for AV-623
        // rolesEnum.BILLING_ADMIN, 
        // rolesEnum.POSTER,
    ],
    'assign_claims': [
        rolesEnum.SUPERADMIN,
        rolesEnum.BILLING_ADMIN,
    ],
    'transition_claims': [
        rolesEnum.SUPERADMIN,
        rolesEnum.BILLING_ADMIN,
    ],
    'edit-worker-assignments': [
        rolesEnum.SUPERADMIN,
        rolesEnum.BILLING_ADMIN,
    ],
    'assign_resources': [
        rolesEnum.SUPERADMIN,
        rolesEnum.BILLING_ADMIN,
    ],
    'edit-payer-taxonomy-codes': [
        rolesEnum.SUPERADMIN,
        rolesEnum.BILLING_ADMIN,
    ],
    'edit-practice-taxonomy-codes': [
        rolesEnum.SUPERADMIN,
        rolesEnum.BILLING_ADMIN,
    ],
}

const permissionsMixin = {
    methods: {
        isViewerOfPath: function (path) {
            const currentUsersRoleNames = listUserRoleNames();
            const rolesThatCanViewPath = this.$router.resolve(path).route?.meta?.viewers;
            if (!rolesThatCanViewPath) return true
            else return rolesThatCanViewPath?.some((role) => currentUsersRoleNames.includes(role))
        },
        hasPermission: function (ability_string) {
            let privileges;
            if (!this.$route?.meta?.privileges || !Object.prototype.hasOwnProperty.call(this.$route?.meta?.privileges, ability_string)) {
                if (!Object.prototype.hasOwnProperty.call(generalPrivileges, ability_string)) {
                    console.log("No privileges defined for " + ability_string);
                    return true;
                }
                privileges = generalPrivileges[ability_string];
            }
            else {
                privileges = this.$route.meta.privileges[ability_string];
            }
            const currentUsersRoleNames = listUserRoleNames();
            return privileges.some((role) => currentUsersRoleNames.includes(role))
        }
    }
}

function getUserStartingPageName() {
    const userRoleNames = listUserRoleNames();
    console.log(userRoleNames);
    if ([
        rolesEnum.SUPERADMIN,
        rolesEnum.WORKER,
        rolesEnum.BILLING_ADMIN,
    ].some((role) => userRoleNames.includes(role))) return 'claims'
    else if ([
        rolesEnum.ISSUES_WORKER,
    ].some((role) => userRoleNames.includes(role))) return 'issues-claims'
    else if ([
        rolesEnum.PHYSICIAN,
        rolesEnum.PRACTICE_ADMIN,
        rolesEnum.ADMINISTRATIVE_PHYSICIAN,
    ].some((role) => userRoleNames.includes(role))) return 'provider-dashboard'
    else if ([
        rolesEnum.PREAUTH_WORKER,
    ].some((role) => userRoleNames.includes(role))) return 'provider-preauths'
}

function goToDashboard(vm) {
    if (vm?.$route?.name == 'provider-login') {
        vm.$router.push({ name: 'provider-dashboard' });
    } else {
        vm.$router.push({ name: getUserStartingPageName() })
    }
}

function logout() {
    authenticated_request({
        method: "post",
        url: make_url("/authentication/logout")
    }).finally(function (response) {
        console.log(response);
        errorStore.commit('logout');
        localStorage.clear(); // Clears everything from local storage
        window.location.replace("/login/");
    });
}

//https://stackoverflow.com/a/53486112
function debounce(fn, delay) {
    let timeout = null
    return function () {
        clearTimeout(timeout)
        let args = arguments
        let self = this
        timeout = setTimeout(function () {
            fn.apply(self, args)
        }, delay)
    }
}

function formatDate(orig_date) {
    let date = "-"
    if (orig_date && (orig_date != "-")) {
        const date_split = orig_date.split("T")[0].split("-");
        const month_map = {
            "01": "Jan",
            "02": "Feb",
            "03": "Mar",
            "04": "Apr",
            "05": "May",
            "06": "Jun",
            "07": "Jul",
            "08": "Aug",
            "09": "Sep",
            "10": "Oct",
            "11": "Nov",
            "12": "Dec",
        }
        const month = Object.prototype.hasOwnProperty.call(month_map, date_split[1]) ? month_map[date_split[1]] : "";
        date = month + " " + date_split[2] + ", " + date_split[0];
    }
    return date;
}

// format an ISO datestring to a short MM/DD/YYYY format
function formatDateShort(orig_date) {
    let date = "-"
    if (orig_date && (orig_date != "-")) {
        const date_split = orig_date.split("T")[0].split("-");
        date = date_split[1] + "/" + date_split[2] + "/" + date_split[0];
    }
    return date;
}

// format an ISO datestring to MM DD, YYYY format
function formatFullDate(dateTime) {
    if (dateTime == null) {
        return null
    }
    const dateObj = new Date(dateTime);
    const splits = dateObj.toDateString().split(' ');
    splits.pop();
    return splits.join(' ') + ', ' + dateObj.getFullYear();
}

// convert ISO datestring to local time in HH:mm format
function formatLocalTimeForSelect(dateTime) {
    if (dateTime == null) {
        return null
    }
    const dateObj = new Date(dateTime);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    return ((hours < 10) ? '0' + hours : hours) + ':' + ((minutes < 10) ? '0' + minutes : minutes);
}

//convert ISO datestring to local date in yyyy-MM-dd format
function formatLocalDateForSelect(dateTime) {
    if (dateTime == null) {
        return null
    }
    const dateObj = new Date(dateTime);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    return year + '-' + ((month < 10) ? '0' + month : month) + '-' + ((day < 10) ? '0' + day : day);
}

function formatTime(dateTime) {
    if (dateTime == null) {
        return null
    }
    const dateObj = new Date(dateTime);
    const splits = dateObj.toLocaleTimeString().split(' ')
    const moreSplits = splits[0].split(':')
    moreSplits.pop()
    return moreSplits.join(':') + ' ' + splits[1]
}

function makeDateTime(date) {
    if (date && (date.indexOf('T') == -1)) {
        return (date + "T00:00:00.000000+00:00");
    }
    else {
        return date;
    }
}

function formatDecimalGeneral(value, options, naMsg="--") {
    if (value !== null && !isNaN(value) && isFinite(value) && value !== '') {
        // convert from string to number
        return Number(value).toLocaleString('en-US', options)
    }
    return naMsg;
}

function formatNumber(value, naMsg="--") {
    return formatDecimalGeneral(value, {
        maximumFractionDigits: 2
    },
    naMsg);
}

function formatDollars(value, cents=true) {
    if (value !== null && !isNaN(value) && value !== '') {
        let options = { 
            style: 'currency', 
            currency: 'USD',
        }
        if (!cents) {
            options = { ...options,
                maximumFractionDigits: 0,
            }
        }
        return new Intl.NumberFormat('en-US', options).format(value);
    }
    return "--"
}

function formatDollarsNoCents(value) {
    // Convenience partial
    return formatDollars(value, false);
}

function formatPercent(value, decimals=true) {
    if (value !== null && !isNaN(value) && isFinite(value) && value !== '') {
        let options = { 
            style: 'percent', 
        }
        if (!decimals) {
            options = { ...options,
                maximumFractionDigits: 0,
            }
        }
        return new Intl.NumberFormat('en-US', options).format(value);
    }
    return "--"
}

function formatDecimal(value, naMsg="--") {
    return formatDecimalGeneral(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    },
    naMsg);
}

function formatDecimalShort(value) {
    return formatDecimalGeneral(value, {
        maximumFractionDigits: 1
    })
}

function formatAddressShort(fullAddress) {
    const parts = fullAddress.split(',')
    return parts[0] + ',' + parts[2];
}


function parseCommaSeparatedIntoPairs(ranges) {
  // "1,2,3,4,5,6" => ["1,2", "3,4", "5,6"]
  if (!ranges) {
    return [];
  }
  const values = ranges.split(",");
  const pairs = values
    .reduce((result, _value, index, array) => {
      if (index % 2 === 0) result.push(array.slice(index, index + 2));
      return result;
    }, [])
    .map((pair) => pair[0] + "," + pair[1]);
  return pairs;
}

const parseAgeRanges = parseCommaSeparatedIntoPairs;


export {
    make_url,
    login,
    logout,
    checkauth,
    userIsAuthenticated,
    userHasJWTSet,
    removeLoginCredentials,
    authenticated_request,
    unauthenticated_request,
    cancellable_request,
    debounce,
    formatDate,
    formatFullDate,
    formatDateShort,
    formatTime,
    formatLocalTimeForSelect,
    formatLocalDateForSelect,
    makeDateTime,
    formatDollars,
    formatDollarsNoCents,
    formatNumber,
    formatPercent,
    formatDecimal,
    formatDecimalShort,
    formatAddressShort,
    listUserRoleNames,
    hasRole,
    getUserStartingPageName,
    hasSuperAdminRole,
    permissionsMixin,
    alertsMixin,
    parseAgeRanges,
};