
//TODO: move to patients.js when that exists
const patientModule = {
  state: () => ({
    trySubmit: false,
    submitted: false,
    patient: null,
    address: null,
    original_address: null,
    isNewPatient: false,
    selected: false,
    created: false,
    showAlerts: false,
    alertMessages: [],
  }),
  mutations: {
    clearPatient(state) {
      state.trySubmit = false;
      state.submitted = false;
      state.patient = null;
      state.address = null;
      state.original_address = null;
      state.showAlerts = false;
      state.alertMessages = [];
    },
    setDefaultPatient(state){
      state.trySubmit = false;
      state.submitted = false;
      state.patient = null;
      state.address = null;
      state.original_address = null;
      state.showAlerts = false;
      state.alertMessages = [];
      state.selected = false;
      state.created = false;
    },
    setTrySubmitPatient(state, value) {
      state.trySubmit = value;
    },
    validSubmitPatient(state) {
      state.showAlerts= false;
      state.alertMessages = [];
      state.trySubmit = false;
      state.submitted = true;
    },
    setPatient(state, patient) {
      state.patient = patient;
    },
    setNewPatientFlag(state, value) {
      state.isNewPatient = value
    },
    patientSelected(state) {
      state.selected = true;
      state.created = false;
    },
    patientCreated(state) {
      state.created = true;
      state.selected = false;
    },
    displayAlerts(state, alerts) {
      state.alertMessages = alerts;
      state.showAlerts = true;
    },
    hideAlerts(state){
      state.showAlerts = false;
      state.alertMessages = [];
    },
  },
}

const insuranceModule = {
  state: () => ({
    showInsuranceAlerts: false,
    insuranceAlertMessages: [],
    insurances: [],
    insurancesSaved: false,
  }),
  getters: {
    insuranceModulePrimaryInsurance: state => {
      // Retrieve the first insurance in the saved insurances that is marked as primary 
      const insurancesArray = state.insurances;
      if (!insurancesArray) {
        return null
      }
      for (const insurance of insurancesArray) {
        if (insurance?.insurance_primacy == 'PRIMARY' || insurance?.primary_insurance == true) {
          return insurance;
        }
      }
      return null
    },
  },
  mutations: {
    setInsurances(state, insurances) {
      state.insurances = insurances;
      state.insurancesSaved = true;
    },
    clearInsurances(state) {
      state.insurances = [];
      state.insurancesSaved = false;
    },
    displayInsuranceAlerts(state, alerts) {
      state.insuranceAlertMessages = alerts;
      state.showInsuranceAlerts = true;
    },
    hideInsuranceAlerts(state){
      state.showInsuranceAlerts = false;
      state.insuranceAlertMessages = [];
    },
  },
}

const visitModule = {
  state: () => ({
    showVisitAlerts: false,
    visitAlertMessages: [],
    visit: null,
    visitSaved: false,
  }),
  mutations: {
    setVisit(state, visit) {
      state.visit = visit;
      state.visitSaved = true;
    },
    clearVisit(state) {
      state.visit = null;
      state.visitSaved = false;
      state.visitAlertMessages = [];
    },
    displayVisitAlerts(state, alerts) {
      state.visitAlertMessages = alerts;
      state.showVisitAlerts = true;
    },
    hideVisitAlerts(state){
      state.showVisitAlerts = false;
      state.visitAlertMessages = [];
    },
  },
}

const codesModule = {
  state: () => ({
    storedICDs: [],
    storedCPTs: [],
    storedPointers: [],
    codesSaved: false,
  }),
  mutations: {
    storeICDCode(state, code) {
      state.storedICDs.push(code)
    },
    storeCPTCode(state, code) {
      state.storedCPTs.push(code)
    },
    storePointer(state, pointer) {
      state.storedPointers.push(pointer)
    },
    storePointerList(state, pointers) {
      state.storedPointers = pointers;
    },
    storeICDCodesList(state, codeList) {
      state.storedICDs = codeList;
    },
    storeCPTCodesList(state, codeList) {
      state.storedCPTs = codeList;
    },
    saveCodes(state){
      state.codesSaved = true;
    },
    clearCodes(state){
      state.storedCPTs = [];
      state.storedICDs = [];
      state.storedPointers = [];
      state.codesSaved = false;
    }
  },
}

const authInfoModule = {
  state: () => ({
    authInfo: {},
  }),
  mutations: {
    setAuthInfo(state, info) {
      state.authInfo = info
    },
    clearAuthInfo(state){
      state.authInfo = {}
    }
  },
}

const preauthStateMap = { "0": "Opened Preauth", 
                          "1": "In Progress", 
                          "2": "Pending Approval", 
                          "3": "Denied", 
                          "4": "Pending Peer-to-Peer", 
                          "5": "Scheduled Peer-to-Peer", 
                          "6": "Cancelled Visit", 
                          "7": "Keep Visit", 
                          "8": "On Hold", 
                          "9": "Partially Approved", 
                          "10": "Approved", 
                          "11": "No Preauth Initiated",
                          "-1": "Unknown",
                          "-2": "No Preauth Required",
};
const preauthStateMapArray = Object.keys(preauthStateMap).map(key => ({ 'state': key, 'name': preauthStateMap[key] }));

const preauthModule = {
  state: () => ({
    showNewPreauth: false,
    showManagePreauth: false,
    showManagePreauthQuickView: false,
    showNewPreauthSuccess: false,
    selectedPreauth: null,
    preauthsUpdated: false,
    preauthStateMap: preauthStateMap,
    preauthStateMapArray: preauthStateMapArray,
  }),
  getters: {
    selectedPreauthPreauthId: state => {
      return state.selectedPreauth?.preauth_id;
    },
  },
  mutations: {
    setShowNewPreauth(state, show) {
      state.showNewPreauth = show
      state.showManagePreauth = false
    },
    setShowNewPreauthSuccess(state, show) {
      state.showNewPreauthSuccess = show
    },
    setShowManagePreauth(state, show) {
      state.showManagePreauth = show
      state.showNewPreauth = false
    },
    setShowManagePreauthQuickView(state, show) {
      state.showManagePreauthQuickView = show
    },
    toggleShowManagePreauthQuickView(state) {
      state.showManagePreauthQuickView = !state.showManagePreauthQuickView
    },
    setSelectedPreauth(state, preauth) {
      state.selectedPreauth = preauth;
    },
    clearSelectedPreauth(state) {
      state.selectedPreauth = null;
    },
    setPreauthsUpdated(state, updated) {
      state.preauthsUpdated = updated;
    },
  }
}

const preauthStoreProto = {
    modules: {
        patient: patientModule,
        preauth: preauthModule,
        insurance: insuranceModule,
        visit: visitModule,
        codes: codesModule,
        authinfo: authInfoModule,
  }
}


export {preauthStoreProto}