const payersStoreProto = {
    state: {
       selected_payer: null,
       payer_types: {},
       selected_resource: null,
       resource_flags: {},
       resource_types: {},
       practices: [],
       workers: [],
    },
    mutations: {
       selectPayer: function(state, payer){
           state.selected_payer = payer;
       },
       clearSelectedPayer: function(state){
           state.selected_payer = null;
       },
       setPayerTypes: function(state, types){
           state.payer_types = types;
       },
       selectResource: function(state, resource){
           state.selected_resource = resource;
       },
       clearResource: function(state){
           state.selected_resource = null;
       },
       setResourceFlags: function(state, flags){
           state.resource_flags = flags;
       },
       setResourceTypes: function(state, types){
           state.resource_types = types;
       },
       setPractices: function(state, practices){
           state.practices = practices;
       },
       setWorkers: function(state, workers){
           state.workers = workers;
       },
    }
}

export { payersStoreProto };