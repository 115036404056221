// centralize the mapping of page-by-page permissions

// We use RBAC system

const rolesEnum = Object.freeze({
    SUPERADMIN : "Superadmin",
    BILLING_ADMIN: "Billing Admin",
    POSTER : "Poster",
    ISSUES_WORKER: "Issues Worker",
    WORKER : "Worker",
    PRACTICE_ADMIN: "Practice Admin",
    ADMINISTRATIVE_PHYSICIAN: "Administrative Physician",
    PREAUTH_WORKER: "Preauth Worker",
    PHYSICIAN: "Physician",
    TRAINEE: "Trainee",
})

export default rolesEnum
