const reportsStoreProto = {
    state: {
       report_map: null,
       report_id: null,
       report_title: null,
       report_description: null,
       base_table: null,
       selected_report: null,
       columns: [],
       filters: [],
       order_by: null,
       start_date: null,
       end_date: null,
       report_assignment_id: null,
       report_document_assignment_id: null,
    },
    mutations: {
      setReportMap: function(state, map){
          state.report_map = map;
      },
      setReportID: function(state, report_id){
        state.report_id = report_id;
      },
      setReportTitle: function(state, report_title){
        state.report_title = report_title;
      },
      setReportDescription: function(state, report_description){
        state.report_description = report_description;
      },
      setBaseTable: function(state, table){
        state.base_table = table;
      },
      setColumns: function(state, columns){
        state.columns = columns;
      },
      setFilters: function(state, filters){
        state.filters = filters;
      },
      setOrderBy: function(state, order_by){
        state.order_by = order_by;
      },
      setStartDate: function(state, start_date){
        state.start_date = start_date;
      },
      setEndDate: function(state, end_date){
        state.end_date = end_date;
      },
      clearCustomReport: function(state){
        state.base_table = null;
        state.columns = [];
        state.filters = [];
        state.order_by = null;
        state.start_date = null;
        state.end_date = null;
      },
      clearAll: function(state){
        state.base_table = null;
        state.columns = [];
        state.filters = [];
        state.order_by = null;
        state.report_id = null;
        state.report_title = null;
        state.report_description = null;
        state.start_date = null;
        state.end_date = null;
      },
      selectReport: function(state, report_id){
        state.selected_report = report_id;
      },
      setReportAssignmentID: function(state, report_id){
        state.report_assignment_id = report_id;
      },
      setReportDocumentAssignmentID: function(state, document_id){
        state.report_document_assignment_id = document_id;
      }
    }
  }

  export { reportsStoreProto };