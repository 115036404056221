const workersStoreProto = {
    state: {
       selected_worker: null,
       active_tab: "WorkerSummary",
       reloadTasks: false,
    },
    mutations: {
       selectWorker: function(state, worker){
           state.selected_worker = worker;
       },
       clearSelectedWorker: function(state){
           state.selected_worker = null;
           state.reloadTasks = false;
       },
       setActiveTab: function(state, component){
           state.active_tab = component;
       },
       refreshTasks: function(state){
           state.reloadTasks = true;
       },
       tasksRefreshed: function(state){
           state.reloadTasks = false;
       },
   }
}

export { workersStoreProto };