<template>
<div class="sidebar-div" :class="navOpen ? 'sidebar-div-opened' : 'sidebar-div-closed'">
  <div id="portal-logo" class="p-3 mr-auto">
      <img :src="require('@/assets/whitelogo.png')" />
  </div>
  <!-- Expand/collapse side-bar actions -->
  <div class="expand-nav-div">
    <b-iconstack
          @click="navOpen = !navOpen"
          v-on:mouseover="expandNavHover = true"
          v-on:mouseout="expandNavHover = false"
          class="cursor-pointer">
      <b-icon icon="circle-fill" :style="expandNavHover || navOpen ?'color:var(--blue)':'color:#88A1DE'" scale="1.25"></b-icon>
      <b-icon v-if="!navOpen" icon="chevron-right" style="color:white;" scale="0.75"></b-icon>
      <b-icon v-else icon="chevron-left" style="color:white" scale="0.75"></b-icon>
  </b-iconstack>
  </div>
    <div id="menu">
      <!-- Side bar is mostly blank on login screen -->
      <div v-if="onProviderLogin">
        <a class="side-nav-link router-link-active selected">
          <b-icon icon="person" scale="1.5" shift-v="1"></b-icon>
          <div v-if="navOpen" class="d-flex align-items-center overflow-hidden ml-3">
            Login</div>
          <div class="neg-radius-active"></div>
        </a>
      </div>
      <!-- Side bar when logged in is populated with options -->
      <div v-else>
        <router-link to="/provider-portal/" :class="'side-nav-link ' + (activeLink == 'providerportal' || activeLink == 'provider-dashboard' ? 'active-sidebar-link' : '')" exact>
          <b-icon icon="layout-wtf" scale="1.5" shift-v="1"></b-icon>
          <div v-if="navOpen" class="d-flex align-items-center overflow-hidden ml-3">
            Dashboard</div>
          <div v-show="activeLink == 'providerportal' || activeLink == 'provider-dashboard'" class="neg-radius-active"></div>
        </router-link>
        <router-link to="/provider-portal/preauths/" :class="'side-nav-link ' + (activeLink == 'provider-preauths' ? 'active-sidebar-link' : '')">
          <b-icon icon="shield-plus" scale="1.5" shift-v="1"></b-icon>
          <div v-if="navOpen" class="d-flex align-items-center overflow-hidden ml-3">
            Preauthorize</div>
          <div v-show="activeLink == 'provider-preauths'" class="neg-radius-active"></div>
        </router-link>
        <router-link to="/provider-portal/estimates/" :class="'side-nav-link ' + (activeLink == 'estimates' ? 'active-sidebar-link' : '')">
          <b-icon icon="receipt-cutoff" scale="1.5" shift-v="1"></b-icon>
          <div v-if="navOpen" class="d-flex align-items-center overflow-hidden ml-3">
            Estimates</div>
          <div v-show="activeLink == 'estimates'" class="neg-radius-active"></div>
        </router-link>
        <router-link to="/provider-portal/reports/" :class="'side-nav-link ' + (activeLink == 'provider-reports' ? 'active-sidebar-link' : '')">
          <b-icon icon="bar-chart-fill" scale="1.5" shift-v="1"></b-icon>
            <div v-if="navOpen" class="d-flex align-items-center overflow-hidden ml-3">
              Reports</div>
            <div v-show="activeLink == 'provider-reports'" class="neg-radius-active"></div>
        </router-link>
      </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'ProviderPortalSidebar',
  props: ['providerLogin'],
  data(){
      return {
          navOpen: false,
          expandNavHover: false,
      }
  },
  computed: {
    activeLink: function() {
      return this.$route.name;
    },
    onProviderLogin: function() {
      return (this.$route.name == 'provider-login')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#portal-logo {
    width: 4em;
    grid-area: logo;
}
#portal-logo img {
    width: 90%;
    margin-left:12px;
}
#menu {
  padding: 40px 0px 24px 16px;
  width:100%;
}
a {
  text-decoration: none;
}
.expand-nav-div {
  position: absolute;
  right:-8px;
  top: 20px;
}
.sidebar-div-closed {
  display: flex;
  flex-direction: column;
  align-items: center;
  position:relative;
  border-radius: 0px 25px 25px 0px;
  width:90px;
  height:100%;
  background: var(--dark);
  transition: ease 0.33s;
}
.sidebar-div-opened {
  display: flex;
  flex-direction: column;
  align-items: center;
  position:relative;
  border-radius: 0px 25px 25px 0px;
  width:200px;
  height:100%;
  background: var(--dark);
  transition: ease 0.33s;
}

.side-nav-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor:pointer;
  position: relative;
  height: 45px;
  width:100%;
  border-radius: 10px 0 0 10px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-light);
}
.side-nav-link:hover {
  color: var(--light);
  background: var(--blue);
  
}
.side-nav-link.active-sidebar-link {
  color: var(--dark);
  background: var(--light) !important;
}
.side-nav-link.active-sidebar-link:hover {
  color: var(--blue);
  background: var(--light);
}

/* hacky way of getting the negative radius section of active/hover tab states */
.neg-radius-active{
  position:absolute;
  z-index: 5;
  top:-25px;
  right: -37px;
  width:62px;
  height:95px;
  background: var(--light); /* fallback */
	background:
		linear-gradient(135deg, transparent 20px, var(--light) 0) top left,
		linear-gradient(45deg,  transparent 20px, var(--light) 0) bottom left;
	background-size: 41% 50%;
	background-repeat: no-repeat;
  background-image:
		radial-gradient(circle at 0 0, rgba(204,0,0,0) 25px, var(--light) 5px),
		radial-gradient(circle at 0 100%, rgba(204,0,0,0) 25px, var(--light) 5px);
  transition: ease 1s;
}
.neg-radius-hover{
  position:absolute;
  z-index: 4;
  top:-25px;
  right: -37px;
  width:62px;
  height:95px;
  background: var(--blue); /* fallback */
	background:
		linear-gradient(135deg, transparent 20px, var(--blue) 0) top left,
		linear-gradient(45deg,  transparent 20px, var(--blue) 0) bottom left;
	background-size: 41% 50%;
	background-repeat: no-repeat;
  background-image:
		radial-gradient(circle at 0 0, rgba(204,0,0,0) 25px, var(--blue) 5px),
		radial-gradient(circle at 0 100%, rgba(204,0,0,0) 25px, var(--blue) 5px);
  transition: ease 1s;
}
</style>