import Vue from 'vue'
import Vuex from 'vuex'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showAlerts: false,
    alertMessages: [],
    userIsAuthenticated: null,
  },
  mutations: {
    displayAlerts(state, alerts) {
      state.alertMessages.push.apply(state.alertMessages, alerts);
      state.showAlerts = true;
    },
    hideAlerts(state) {
        state.showAlerts = false;
        state.alertMessages = [];
    },
    recordAuthenticated(state) {
      state.userIsAuthenticated = true;
    },
    logout(state) {
      state.userIsAuthenticated = false;
    }
  }
})