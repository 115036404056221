import { make_url, authenticated_request } from '../common'

const practicesStoreProto = {
    state: {
        add_practice: false,
        selected_practice: null,
        selected_practice_address: null,
        selected_practice_billing_address: null,
        practice_types: {},
        selected_resource: null,
        resource_flags: {},
        resource_types: {},
        payers: [],
        workers: [],

        practiceListNeedsRefresh: false,
        providersTypes: null,
    },
    mutations: {
        setPracticeListNeedsRefresh: function (state, bool) {
            state.practiceListNeedsRefresh = bool;
        },
        setAddPractice: function (state, bool) {
            state.add_practice = bool;
        },
        selectPractice: function (state, practice) {
            state.selected_practice = practice;
        },
        clearPractice: function (state) {
            state.selected_practice = null;
            state.selected_practice_address = null;
            state.selected_practice_billing_address = null;
        },
        setPracticeTypes: function (state, types) {
            state.practice_types = types;
        },
        selectResource: function (state, resource) {
            state.selected_resource = resource;
        },
        clearResource: function (state) {
            state.selected_resource = null;
        },
        setResourceFlags: function (state, flags) {
            state.resource_flags = flags;
        },
        setResourceTypes: function (state, types) {
            state.resource_types = types;
        },
        setPayers: function (state, payers) {
            state.payers = payers;
        },
        setWorkers: function (state, workers) {
            state.workers = workers;
        },
        setSelectedPracticeAddress: function (state, address) {
            state.selected_practice_address = address;
        },
        setSelectedPracticeBillingAddress: function (state, address) {
            state.selected_practice_billing_address = address;
        },
        setProvidersTypes: function (state, types) {
            state.providersTypes = types;
        }
    },
    actions: {
        fetchProvidersTypes: function (context) {
            context.commit('setProvidersTypes', []);
            authenticated_request({
                method: "get",
                url: make_url("/forms/providers/filters")
            }).then(function (response) {
                const items = response.data?.types;
                context.commit('setProvidersTypes', items);
            }).catch(function (error) {
                console.log(error.response)
                context.commit('setProvidersTypes', null);
            });
        },
    }
}

export { practicesStoreProto };