<template>
<div class="sidebar-div" id="portal-sidebar">
    <div id="menu">
        <router-link to="/claims/" class="side-nav-link">
          <div><b-icon icon="file-earmark-medical" scale="1.3" shift-v="1"></b-icon> Claims</div>
        </router-link>
        <router-link 
          to="/issues-claims/" 
          v-if="isViewerOfPath('/issues-claims/')" 
          class="side-nav-link">
          <div><b-icon icon="exclamation-triangle" scale="1.2" shift-v="1"></b-icon> Issues</div>
        </router-link>
        <router-link 
          to="/workers/" 
          v-if="isViewerOfPath('/workers/')" 
          class="side-nav-link">
          <div><b-icon icon="people" scale="1.3" shift-v="1"></b-icon> Workers</div>
        </router-link>
        <router-link 
          to="/me/" 
          v-if="isViewerOfPath('/me/')" 
          class="side-nav-link">
          <div><b-icon icon="person-check" scale="1.3" shift-v="1"></b-icon> My Work</div>
        </router-link>
        <router-link to="/practices/" class="side-nav-link">
          <div><b-icon icon="shop" scale="1.2" shift-v="1"></b-icon> Practices</div>
        </router-link>
        <router-link to="/payers/" class="side-nav-link">
          <div><b-icon icon="shield-plus" scale="1.3" shift-v="1"></b-icon> Payers</div>
        </router-link>
        <router-link to="/reports/" 
          v-if="isViewerOfPath('/reports/')" 
          class="side-nav-link">
          <div><b-icon icon="bar-chart-fill" scale="1.2" shift-v="1"></b-icon> Reports</div>
        </router-link>
        <router-link to="/codelookup/" target="_blank" class="side-nav-link">
          <div><b-icon icon="search" scale="1.2" shift-v="1"></b-icon> Code Lookup</div>
        </router-link>
        <router-link to="/admin/"
          v-if="isViewerOfPath('/admin/')" 
          class="side-nav-link">
          <div><b-icon icon="gear" scale="1.3" shift-v="1"></b-icon> Settings</div>
        </router-link>
         <router-link 
          :to="providerPortalLink" 
          v-if="isViewerOfPath(providerPortalLink)" 
          target="_blank" 
          class="side-nav-link">
          <div><b-icon icon="box-arrow-right" scale="1.3" shift-v="1"></b-icon> Provider Portal</div>
        </router-link>
    </div>
</div>
</template>

<script>
import { permissionsMixin } from "../common.js"

export default {
  name: 'Sidebar',
  mixins: [permissionsMixin,],
  computed: {
    providerPortalLink: function() {
      if (this.$route.name == 'login') {
        return '/provider-login/'
      } else {
        return '/provider-portal/'
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#menu {
  padding: 25px 5px 20px 0px;
  display: flex;
  flex-direction: column;
}
a {
  text-decoration: none;
}
.side-nav-link {
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 14px;
  height: 45px;
  width:100%;
}
.side-nav-link  {
  color: #929BAA !important;
}
.side-nav-link:hover  {
  color: #045E9E !important;
  border-left: 3px solid #045E9E !important;
}
.b-icon {
  margin-right:3px;
}
.side-nav-link.router-link-active {
  color: #045E9E !important;
  border-left: 3px solid #045E9E !important;
  pointer-events: none;
}
</style>