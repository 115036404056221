import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { claimsStoreProto } from '../claims/claimStore.js'
import { payersStoreProto } from '../payers/payersStore.js'
import { practicesStoreProto } from '../practices/practicesStore.js'
import { workersStoreProto } from '../workers/workersStore.js'
import { reportsStoreProto } from '../reports/reportsStore.js'
import { providerPortalStoreProto } from '../providerPortal/providerPortalStore.js'
import { preauthStoreProto } from '../preauths/preauthStore.js'
import router from '@/router.js'

import '@/assets/custom.scss'
import '@/assets/main.css'

import App from "./App.vue"

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

Vue.use(Vuex);
Vue.use(VueRouter);

const store = new Vuex.Store({ modules: {
  claims: claimsStoreProto,
  payers: {
    namespaced: true,
    ...payersStoreProto
  },
  practices: {
    namespaced: true,
    ...practicesStoreProto
  },
  workers: {
    namespaced: true,
    ...workersStoreProto
  },
  reports: {
    namespaced: true,
    ...reportsStoreProto
  },
  providerPortal: {
    namespaced: true,
    ...providerPortalStoreProto
  },
  preauths: {
    namespaced: true,
    ...preauthStoreProto
  },
}});

new Vue({
  router: router,
  store: store,
  beforeCreate() { this.$store.commit('initializeStore'); },
  render: h => h(App),
}).$mount('#app');
