const providerPortalStoreProto = {
    state: {
        activeNavTab: 'provider-dashboard',
        globalBackgroundWhite: false,
        selectedProviderId: null
    },
    mutations: {
        setActiveNavTab: function(state, tab){
            state.activeNavTab = tab
        },
        setBackgroundWhite(state, bool){
            state.globalBackgroundWhite = bool;
        },
        setSelectedProviderId: function(state, provider_id){
            state.selectedProviderId = provider_id;
        }
        
    }
}

export { providerPortalStoreProto };