<template>
  <div class="alert-div">
    <b-alert
        v-for="(alert, index) in alertMessages"
        :key="index"
        :show="showAlerts"
        dismissible
        variant="danger">
        <b>{{alert}}</b>
    </b-alert>
  </div>
</template>

<script>
import store from '../errorStore'
export default {
    store,
    name: 'ShowGeneralErrors',
    data () {
        return {
        }
    },
    computed: {
        showAlerts() {
            return store.state.showAlerts;
        },
        alertMessages() {
            return store.state.alertMessages;
        },
    }
}
</script>
